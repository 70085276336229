import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import store from '@/store'
import admin from '@/router/admin'
import drive from '@/router/drive'
import web from '@/router/web'
import {local, LOCAL_STORAGE} from '@/utils/storage'
// import cookie from '@/utils/cookie'
import NProgress from "nprogress"
import 'nprogress/nprogress.css'

const routes: Array<RouteRecordRaw> = [
    admin,
    drive,
    web,
    {
        path: '/',
        name: 'index',
        redirect: '/web/center',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
        meta: {
            title: '登录'
        },
        children: [

        ]
    },
    {
        path: '/oauth2/authorize/coze',
        name: 'oauth2.authorize.coze',
        component: () => import('@/views/login/oauth_coze.vue'),
        meta: {
            title: 'OAuth'
        }
    },
    {
        path: '/web2/survey/answer/submit',
        name: 'surveyInfo',
        component: () => import('@/views/web/center/survey/add-answer.vue'),
        meta: {
            icon: 'survey',
            title: '问卷中心',
            isClose: true, // 顶部是否显示关闭按钮
            isBreadcrumb: true // 顶部是否显示导航路径
        }
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 路由加载前
router.beforeEach(async (to, from, next) => {
    NProgress.start()
    if (!local.get(LOCAL_STORAGE.token)) {
        const pathes = ['/login', '/web2/survey/answer/submit']
        if (pathes.includes(to.path)) {
            next();
            // NProgress.done();
        } else {
            next(`/login?redirect=${encodeURIComponent(to.fullPath)}`);
        }
    } else {
        // cookie.set('token',local.get(LOCAL_STORAGE.token))
        if (!store.state.user.user.id) {
            store.commit('user/setUser', local.get(LOCAL_STORAGE.user))
            store.commit('user/setCompany', local.get(LOCAL_STORAGE.company))
            await store.dispatch('user/setUserInfo')
        }
        next()
    }

    console.log('路由加载前', 'from:', from, '-to:', to)
});

router.afterEach(async (to, from) => {
    // console.log('路由加载后', 'from:', from, '-to:', to)
    NProgress.done()
    document.title = <string>to.meta.title
})

export default router
